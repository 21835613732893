<template>
  <div class="jobauth">
    <mt-header :title="$t('workInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
    </mt-header>
    <step-item :step="2" />
    <div class="content">
        <div class="item">
          <div class="title">{{ $t('occupationType') }}</div>
          <mt-field
            :placeholder="$t('occupationTypePlaceholder')"
            readonly
            :state="jobTypeCodeState"
            v-model="(Enum.jobType.filter(e => e.code === form.jobTypeCode)[0]||{}).name"
            @click.native="onPopupPicker(Enum.jobType, (e) => {
              form.jobTypeCode = e.code;
              BEHAVIOR_ADD({
                id: 'P02_C02_S_JOBTYPE',
                newValue: form.jobTypeCode
              })
            })"
          >
            <mt-spinner v-if="Enum.jobTypeLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('averageMonthlyIncome') }}</div>
          <mt-field
            :placeholder="$t('averageMonthlyIncomePlaceholder')"
            readonly
            :state="monthlyIncomeState"
            v-model="(Enum.monthlyIncome.filter(e => e.code == form.monthlyIncome)[0]||{}).name"
            @click.native="onPopupPicker(Enum.monthlyIncome, (e) => {
              form.monthlyIncome = e.code;
              BEHAVIOR_ADD({
                id: 'P02_C03_S_MONTHLYINCOME',
                newValue: form.monthlyIncome
              })
            })"
          >
            <mt-spinner v-if="Enum.monthlyIncomeLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('companyName') }}</div>
          <mt-field
            :placeholder="$t('companyNamePlaceholder')"
            :state="companyState"
            v-model="form.company"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P02_C06_I_COMPANY',
              newValue: form.company
            })"
          ></mt-field>
        </div>
        <div class="item">
          <div class="title optional">{{ $t('workPhone') }}</div>
          <mt-field
            :placeholder="$t('workPhonePlaceholder')"
            type="tel"
            v-mask="'#### #### #### ###'"
            :state="maskedCompanyPhoneState"
            v-model="form.maskedCompanyPhone"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P02_C07_I_COMPANYPHONE',
              newValue: form.maskedCompanyPhone
            })"
          ></mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('companyAddressSelect1') }}</div>
          <mt-field
            :placeholder="$t('companyAddressPlaceholder')"
            readonly
            :state="companyAddrProvinceCodeState"
            v-model="(Enum.prov.filter(e => e.code == form.companyAddrProvinceCode)[0]||{}).name"
            @click.native="onPopupPicker(Enum.prov, (e) => {
              form.companyAddrProvinceCode = e.code;
              BEHAVIOR_ADD({
                id: 'P02_C08_S_COMPANYPROVINCE',
                newValue: form.companyAddrProvinceCode
              })
            })"
          >
            <mt-spinner v-if="Enum.provLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('companyAddressSelect2') }}</div>
          <mt-field
            :placeholder="$t('companyAddressPlaceholder')"
            readonly
            :state="companyAddrCityCodeState"
            v-model="(Enum.city.filter(e => e.code == form.companyAddrCityCode)[0]||{}).name"
            @click.native="onPopupPicker(Enum.city, (e) => {
              form.companyAddrCityCode = e.code;
              BEHAVIOR_ADD({
                id: 'P02_C09_S_COMPANYCITY',
                newValue: form.companyAddrCityCode
              })
            })"
          >
            <mt-spinner v-if="Enum.cityLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('detailedCompanyAddress') }}</div>
          <mt-field
            :placeholder="$t('detailedCompanyAddressPlaceholder')"
            :state="companyAddrDetailState"
            v-model="form.companyAddrDetail"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P02_C10_S_MOBILETYPE',
              newValue: form.companyAddrDetail
            })"
          ></mt-field>
        </div>
      <div class="item">
        <div class="title">{{ $t('incumbency') }}</div>
        <mt-field
            :placeholder="$t('incumbencyPlaceholder')"
            readonly
            :state="incumbencyState"
            v-model="(Enum.incumbency.filter(e => e.code == form.incumbency)[0]||{}).name"
            @click.native="onPopupPicker(Enum.incumbency, (e) => {
              form.incumbency = e.code;
              BEHAVIOR_ADD({
                id: 'P02_C09_S_COMPANYCITY',
                newValue: form.incumbency
              })
            })"
        >
          <mt-spinner v-if="Enum.cityLoading" type="snake" color="#4F9B41" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
        </mt-field>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules } from "../../utils/rules";
import { applyStep } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import stepItem from '../../components/stepItem.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Jobauth',
  mixins: [mixin],
  components: {
    popupPicker,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      form: {
        jobTypeCode: '', // 职务
        monthlyIncome: '', // 平均月收入
        company: '', // 公司名称
        maskedCompanyPhone: '', // 工作电话
        companyAddrProvinceCode: '', // 公司地址
        companyAddrCityCode: '', // 公司地址
        companyAddrDetail: '', // 公司详细地址
        incumbency: '',
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    companyState() {
      this.onStorageItem('company')
      return this.isCheck ? emptyRules(this.form.company) : null;
    },
    industryCodeState() {
      this.onStorageItem('industryCode')
      return this.isCheck ? emptyRules(this.form.industryCode) : null;
    },
    jobTypeCodeState() {
      this.onStorageItem('jobTypeCode')
      return this.isCheck ? emptyRules(this.form.jobTypeCode) : null;
    },
    maskedCompanyPhoneState() {
      this.onStorageItem('maskedCompanyPhone')
      // return this.isCheck ? companyPhoneRules(this.form.maskedCompanyPhone) : null;
      return null;
    },
    monthlyIncomeState() {
      this.onStorageItem('monthlyIncome')
      return this.isCheck ? emptyRules(this.form.monthlyIncome) : null;
    },
    paydayTypeState() {
      this.onStorageItem('payCycle')
      return this.isCheck ? emptyRules(this.form.payCycle) : null;
    },
    paydayState() {
      this.onStorageItem('payDay')
      return this.isCheck ? emptyRules(this.form.payDay) : null;
    },
    companyAddrProvinceCodeState() {
      this.onStorageItem('companyAddrProvinceCode')
      return this.isCheck ? emptyRules(this.form.companyAddrProvinceCode) : null;
    },
    companyAddrCityCodeState() {
      this.onStorageItem('companyAddrCityCode')
      return this.isCheck ? emptyRules(this.form.companyAddrCityCode) : null;
    },
    companyAddrDetailState() {
      this.onStorageItem('companyAddrDetail')
      return this.isCheck ? emptyRules(this.form.companyAddrDetail) : null;
    },
    incumbencyState() {
      this.onStorageItem('incumbency')
      return this.isCheck ? emptyRules(this.form.incumbency) : null;
    },
    isWork() {
      if (!this.form.industryCode) {
        return true
      }
      for (let i = 0; i < this.Enum.industry.length; i++) {
        if (this.Enum.industry[i].code == this.form.industryCode && this.Enum.industry[i].attr1 == 'N') {
          return false
        }
      }
      return true
    }
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    this.init()
  },
  watch: {
    // 监听省市发生变化重置区内容
    'form.companyAddrProvinceCode': {
      handler(val) {
        if (!val) return
        this.GET_DISTRICT_CITY(val).then(() => {
          let length = this.Enum.city.filter(e => e.code == this.form.companyAddrCityCode).length
          if (!length) {
            this.form.companyAddrCityCode = ''
          }
        })
      },
      immediate: true
    },
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P02_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P02_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_INDUSTRY(); // 获取行业
      this.GET_PROFESSION(); // 获取职务
      this.GET_DISTRICT_PROV(); // 获取省市
      this.GET_MONTHLY_INCOME(); // 获取平均月收入
      this.GET_INCUMBENCY(); // 获取在职时长
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('jobTypeCode') >= 0 && this.jobTypeCodeState != "success") {
        return this.$t('occupationTypeError')
      }
      if (list.indexOf('monthlyIncome') >= 0 && this.monthlyIncomeState != "success") {
        return this.$t('averageMonthlyIncomeError')
      }
      if (list.indexOf('company') >= 0 && this.companyState != "success") {
        return this.$t('companyNameError')
      }
      if (list.indexOf('maskedCompanyPhone') >= 0 && this.maskedCompanyPhoneState != "success") {
        return this.$toast(this.maskedCompanyPhoneState == "warning"
          ? this.$t("workPhonePlaceholder")
          : this.$t("workPhoneError"));
      }
      if (list.indexOf('companyAddrProvinceCode') >= 0 && this.companyAddrProvinceCodeState != "success") {
        return this.$toast(this.companyAddrProvinceCodeState == "warning"
          ? this.$t("companyAddressPlaceholder")
          : this.$t("companyAddressError"));
      }
      if (list.indexOf('companyAddrCityCode') >= 0 && this.companyAddrCityCodeState != "success") {
        return this.$toast(this.companyAddrCityCodeState == "warning"
          ? this.$t("companyAddressPlaceholder")
          : this.$t("companyAddressError"));
      }
      if (list.indexOf('companyAddrDetail') >= 0 && this.companyAddrDetailState != "success") {
        return this.$toast(this.companyAddrDetailState == "warning"
          ? this.$t("detailedCompanyAddressPlaceholder")
          : this.$t("detailedCompanyAddressError"));
      }
      if (list.indexOf('incumbency') >= 0 && this.incumbencyState != "success") {
        return this.$toast(this.incumbencyState == "warning"
          ? this.$t("incumbencyPlaceholder")
          : this.$t("incumbencyError"));
      }
    },
    // 提交下一步
    async submit() {
      this.isCheck = true;
      var error = this.validation([
        'company',
        'industryCode',
        'jobTypeCode',
        // 'maskedCompanyPhone',
        'monthlyIncome',
        'companyAddrProvinceCode',
        'companyAddrCityCode',
        'companyAddrDetail',
        'incumbency',
      ]);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('02_JOB_AUTH', { content_name: '02_JOB_AUTH' })
        }
        // Google Analytics  JOB_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'JOB_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        currentStep: 2,
        totalSteps: 8,

        industry: (this.Enum.industry.filter(e => e.code == this.form.industryCode)[0]||{}).name,
        jobType: (this.Enum.jobType.filter(e => e.code == this.form.jobTypeCode)[0]||{}).name,
        companyAddrProvince: (this.Enum.prov.filter(e => e.code == this.form.companyAddrProvinceCode)[0]||{}).name,
        companyAddrCity: (this.Enum.city.filter(e => e.code == this.form.companyAddrCityCode)[0]||{}).name,

        applyId: this.user.applyId,
        ...this.form,
        companyPhone: this.form.maskedCompanyPhone.replace(/\s/g, '')
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/relationshipauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('03_JOB_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.jobauth {
  background-color: #FBFBFF;
  .content {
    margin: 10px;
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &.optional {
          &::after {
            content: '';
          }
        }
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .iconfont {
    margin: 0 5px;
    font-size: 12px;
    color: #000000;
  }
  .bottom {
    padding: 40px 20px;
    .mint-button {
      border-radius: 20px;
    }
  }
}
</style>