<template>
  <div class="stepItem">
    <!-- <div class="step">
      <div v-for="index in max" :key="index" :class="['item', {'select': index <= step}]">
        <div>{{ index }}</div>
      </div>
    </div> -->
    <!-- <div class="progress">
      <mt-progress :value="progress" :barHeight="8">
        <div slot="end">{{ progress }}%</div>
      </mt-progress>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'StepItem',
  props: {
    max: {
      type: Number,
      default: 5
    },
    step: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {

    }
  },
  computed: {
    progress() {
      return this.step / this.max * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.stepItem {
  margin: 25px 10px 10px 10px;
  overflow: hidden;
  .step {
    margin: 10px;
    text-align: center;
    .item {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      background-color: #E2E2E2;
      color: #1D1D1D;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      margin-left: 20px;
      font-size: 12px;
      &.select {
        background-color: #A9D8F4;
        color: #FFDF0A;
        font-size: 16px;
        font-weight: bold;
        padding: 4px;
        div {
          width: 100%;
          height: 100%;
          background-color: #4F9B41;
          border-radius: 100%;
        }
        &::before {
          content: '';
          background-color: #4F9B41;
          height: 2px;
          top: 16px;
        }
      }
      &::before {
        content: '';
        position: absolute;
        background-color: #E2E2E2;
        height: 1px;
        width: 20px;
        left: -20px;
        top: 12px;
      }
      &:first-child {
        margin-left: 0px;
        &::before {
          display: none;
        }
      }
    }
  }
  .progress {
    padding: 0px 20px 0px 20px;
  }
}
</style>